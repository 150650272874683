@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');

.search_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 17px;
}

@media (min-width: 280px) and (max-width: 750px) {
    .search_icon {
        height: 20px;
        width: 20px;
        z-index: 99999;
    }
    .search_input {
        padding: 8px 8px 8px 28px;
        margin-left: -24px;
        border: 1px solid #EF4444;
        border-radius: 8px;
        width: 70%;
        z-index: 9999;
    }
    .search_icon_z_index {
        height: 20px;
        width: 20px;
        z-index: 10;
    }
    .search_input_z_index {
        padding: 8px 8px 8px 28px;
        margin-left: -24px;
        border: 1px solid #EF4444;
        border-radius: 8px;
        width: 70%;
        z-index: 9;
    }
    .search_results {
        background-color: white;
        width: 300px;
        height: 362px;
        margin-top: 30px;
        overflow-y: auto;
        position: absolute;
        box-shadow: 2px 2px 5px 10px rgba(16, 24, 40, 0.05);
        z-index: 9999;
    }
    .result_item {
        border-bottom: 1px solid #F4F4F5;
        cursor: pointer;
        z-index: 9999;
    }
}

@media (min-width: 751px) {
    .search_icon {
        height: 20px;
        width: 20px;
        z-index: 3;
        margin-top: -105px;
    }
    .search_input {
        padding: 8px 8px 8px 28px;
        margin-left: -24px;
        border: 1px solid #EF4444;
        border-radius: 8px;
        width: 30%;
        margin-top: -105px;
    }
    .search_icon_z_index {
        height: 20px;
        width: 20px;
        z-index: 10;
        margin-top: -105px;
    }
    .search_input_z_index {
        padding: 8px 8px 8px 28px;
        margin-left: -24px;
        border: 1px solid #EF4444;
        border-radius: 8px;
        width: 30%;
        margin-top: -105px;
        z-index: 9;
    }
    .search_results {
        background-color: white;
        width: 500px;
        height: 362px;
        overflow-y: auto;
        position: absolute;
        box-shadow: 2px 2px 5px 10px rgba(16, 24, 40, 0.05);
        z-index: 9999;
    }
    .result_item {
        border-bottom: 1px solid #F4F4F5;
        cursor: pointer;
    }
}

.search_results_center {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    margin-top: -30px;
}

.resultName {
    font-family: 'Roboto';
    font-weight: 600;
    color: #EF4444;
}

.resultWay {
    font-family: 'Roboto';
}