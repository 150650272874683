@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');

.MapToolbarWrap {
  width: 32px !important;
  box-sizing: border-box;

  .zoomWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 32px;
    height: 80px;
    border-radius: 16px;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15),
      0px -1px 6px rgba(16, 24, 40, 0.08);
    margin-bottom: 24px;
    padding-top: 4px;
    padding-bottom: 4px;
    transition: all 0.3s linear;
    overflow: hidden;

    .zoomWrapBtn {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      cursor: pointer;
      background-color: #fff;
    }
  }

  .expandCollapseBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: #ffffff;
    border: none;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15),
      0px -1px 6px rgba(16, 24, 40, 0.08);
    border-radius: 50%;
    .collapseImg {
      width: 20px;
      height: 20px;
    }
  }
}

.tooltipText {
  font-family: 'Roboto'!important;
  font-weight: 400!important;
  font-size: 12px!important;
  line-height: 18px!important;
  color: #ffffff!important;
}
