.wrapper {
    background-color: white;
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
}

.buttons_wrapper {
    display: flex;
    width: 100%;
    justify-content: end;
    margin-right: 32px;
}

.buttons_wrapper a {
    text-decoration: none;
}

@media (min-width: 280px) and (max-width: 750px) {
    .logo {
        display: flex;
        justify-content: start;
        margin-left: 32px;
        width: 100px;
        height: 40px;
        margin-top: 10px;
    }
    .logo_item {
        width: 140px;
        height: 30px;
    }
}

@media (min-width: 751px) {
    .logo {
        display: flex;
        justify-content: start;
        margin-left: 32px;
        width: 100px;
        height: 40px;
    }
}

.signIn_button {
    border: 1px solid #D0D5DD;
    border-radius: 10px;
    padding: 8px 8px 8px 8px;
    margin-right: 16px;
    background-color: white;
    color: black;
    cursor: pointer;
}
.signUp_button {
    border-radius: 10px;
    padding: 8px 8px 8px 8px;
    background-color: #EF4444;
    color: white;
    cursor: pointer;
}