@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');

.mapContainer {
  /* position: relative; */
  font-family: 'Roboto';
  text-align: center;
}

.header {
  display: flex;
}

.toolBar {
  margin-bottom: 20px;
}
.toolBar button {
  margin-right: 10px;
}

.searchBar {
  margin-bottom: 20px;
  margin-right: 40px;
}
.search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  width: 300px;
  height: 40px;
  font-size: 20px;
  border: 1px solid grey;
}
.searchBar button {
  width: 300px;
  height: 20px;
  display: block;
  margin-bottom: 10px;
  background-color: #fff;
  border: none;
  text-align: left;
  padding-left: 20px;
  cursor: pointer;
}
.wrap {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.canvasWrap {
  position: relative;
  height: 600px;
}
.canvasWrap canvas {
  display: block;
  transition: all 0.5s linear;
  /* border: 1px solid; */
}

.toolbarWrap {
  position: absolute;
  width: 32px;
  bottom: 38px;
  right: 0px;
  transition: all 0.5s linear;
  margin-right: 20px;
  z-index: 9999;
}

@media (min-width: 280px) and (max-width: 750px) {
  .menu {
    position: fixed;
    width: 250px;
    max-height: 40vh;
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;
    transition: all 0.5s linear;
    overflow: hidden;
    z-index: 9999;
    background-color: #fff;
    padding-top: 24px;
  }
  .info {
    position: relative;
    width: 250px;
    height: 80px;
    border-radius: 8px;
    padding: 5px 16px 5px 16px;
    margin-bottom: 24px;
    text-align: left;
    color: #fff;
  }
  .menuAccordionWrap {
    width: 285px;
    height: 60%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .menuWrap {
    padding: 1px;
    transition: all 0.5s linear;
    width: 500px;
  }
  .infoСategory {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
  }
  .infoCompanyAmount {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
  }
}

@media (min-width: 751px) {
  .menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 336px;
    max-height: 100vh;
    display: flex;
    justify-content: flex-end;
    transition: all 0.5s linear;
    overflow: hidden;
    z-index: 98;
    background-color: #fff;
    padding-top: 24px;
  }
  .info {
    position: relative;
    width: 344px;
    height: auto;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;
    text-align: left;
    color: #fff;
  }
  .menuAccordionWrap {
    width: 375px;
    height: 70%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .menuWrap {
    padding: 1px;
    transition: all 0.5s linear;
    width: 500px;
  }
  .infoСategory {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 22px;
  }
  .infoCompanyAmount {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 133% */
  
    text-transform: uppercase;
  }
}

.closeMenuBtn {
  display: flex;
  align-items: center;
  height: 16px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #98a2b3;
  cursor: pointer;
  border: none;
  background-color: #fff;
  margin-top: 30vh;
}

.closeMenuBtnImg {
  display: block;
  width: 30px;
  height: 30px;
  margin-right: 9px;
}

.closeMenuLable {
  font-size: 20px;
}

.infoBreadCrumbs {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
}

.triggerWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 336px;
  height: 40px;
  padding-left: 16px;
  padding-right: 22px;
  padding-top: 4px;
  padding-bottom: 4px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  margin-bottom: 1px;
  cursor: pointer;
}
.triggerContent {
  display: flex;
  align-items: center;
  /* width: 290px; */
}

.triggerflag {
  display: block;
  width: 16px;
  height: 16px;
  margin-right: 24px;
  border-radius: 50%;
}

.triggerLogo {
  display: block;
  width: 45px;
  height: 45px;
  margin-right: 12px;
}

.triggerText {
  width: 220px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  color: #101828;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.Collapsible__contentInner {
  padding: 25px 16px;
}

.itemText {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #475467;
  text-align: left;
  margin-top: 0;
}

.linksWrap {
  display: flex;
  align-items: center;
}

.itemLink {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.linkImg {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.linkImg.linkedin {
  width: 25px;
  height: 25px;
}
.linkText {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  /* identical to box height, or 160% */

  display: flex;
  align-items: center;
  color: #2e90fa;
}
