@media (min-width: 280px) and (max-width: 750px) {
    .wrapper {
        font-family: 'Roboto';
        width: 80px;
        height: 80px;
        background-color: #EF4444;
        border-radius: 100px;
        color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        font-weight: 600;
        cursor: pointer;
        margin-top: 81px;
        margin-right: 16px;
        margin-left: 32px;
        z-index: 999;
    }
    .add_company_button {
        font-family: 'Roboto';
        font-weight: 600;
        font-size: 13px;
        width: 80px;
        height: 80px;
        background-color: #EF4444;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-self: end;
        margin-top: 81px;
        margin-left: 32px;
        text-align: center;
        text-decoration: none;
        color: white;
        position: fixed;
        right: 0;
        margin-right: 32px;
        z-index: 2;
    }
    .ecosustem_wrapper {
        width: 80px;
        height: 80px;
        border: 1px solid #EF4444;
        background-color: white;
        border-radius: 100px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        font-weight: 500;
        cursor: pointer;
        z-index: 999;
    }
    .ecosystem_image {
        height: 50px;
        width: 50px;
    }
    .ecosystemsList {
        display: grid;
        grid-template-columns: 100px 100px;
        grid-row-gap: 30px;
        margin-top: 80px;
        z-index: 999;
    }
}

@media (min-width: 751px) {
    .wrapper {
        font-family: 'Roboto';
        width: 120px;
        height: 120px;
        background-color: #EF4444;
        border-radius: 100px;
        color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        font-weight: 600;
        cursor: pointer;
        margin-top: 81px;
        margin-right: 16px;
        margin-left: 32px;
        z-index: 999;
    }
    .add_company_button {
        font-family: 'Roboto';
        font-weight: 600;
        width: 120px;
        height: 120px;
        background-color: #EF4444;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-self: end;
        margin-top: 81px;
        margin-left: 32px;
        text-align: center;
        text-decoration: none;
        color: white;
        position: fixed;
        right: 0;
        margin-right: 32px;
        z-index: 2;
    }
    .ecosustem_wrapper {
        width: 120px;
        height: 120px;
        border: 1px solid #EF4444;
        background-color: white;
        border-radius: 100px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        font-weight: 500;
        cursor: pointer;
        z-index: 999;
    }
    .ecosystem_image {
        height: 80px;
        width: 80px;
    }
    .ecosystemsList {
        display: grid;
        grid-template-columns: 136px 136px 136px 136px 136px 136px 136px 136px;
        grid-row-gap: 30px;
        margin-top: 80px;
        z-index: 999;
    }
}

.inline {
    display: flex;
    position: fixed;
    z-index: 999;
}

.grayBackground {
    background-color: #A6A6A6;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 99;
    opacity: .5;
}

.buttons_wrapper {
    width: 100%;
    display: flex;
    margin-top: 200px;
}

.buttons_wrapper a {
    text-decoration: none;
    color: black;
    font-size: 14px;
}

.bag {
    margin-right: 10px;
}

.getScreenShot_button {
    margin-left: 33.5vw;
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}